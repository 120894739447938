// AnswerParser.tsx
import { renderToStaticMarkup } from "react-dom/server"
import { citationColors } from "@/theme"
import { processFileName } from "@/pages/documents/Documents"
import { marked } from "marked"
import axios from "axios"

export type HtmlParsedAnswer = {
    fragments: AnswerFragment[]
    citations: string[]
    followupQuestions: string[]
}

export enum AnswerFragmentType {
    Citation = "citation",
    Chart = "chart",
    Text = "text",
    Image = "image",
}

export type AnswerFragment = {
    text: any
    type: AnswerFragmentType
}

function decodeHtmlEntities(str: string) {
    const doc = new DOMParser().parseFromString(str, "text/html")
    return doc.documentElement.textContent
}

const emptySymbol = "&nbsp;"

function prepareAnswer(answer: string) {
    let newAnswer = ""
    let previousLine = ""
    let firstLineOfTable = ""
    let isTableProcessed = false

    // Remove the redundant ```markdown ``` wrapper
    answer = answer.replace(/```markdown\s*([\s\S]*?)```/gs, "$1")

    answer.split("\n").forEach(line => {
        let newLine = line

        const isTableRow = line.startsWith("|")

        if (previousLine.startsWith("|") && !isTableRow) {
            newLine = `\n${line}`
        }

        // if table has no header row,then we need add empty header row to make it work with markdown
        if (isTableRow && firstLineOfTable !== "" && !isTableProcessed) {
            if (!line.includes("----")) {
                const numberColumns = firstLineOfTable.split("|").length - 2
                //remove previous line
                newAnswer = newAnswer.slice(0, -previousLine.length - 1)

                newAnswer += "|"
                for (let i = 0; i < numberColumns; i++) {
                    newAnswer += " |"
                }

                newAnswer += "\n|"
                for (let i = 0; i < numberColumns; i++) {
                    newAnswer += "----|"
                }

                newAnswer += `\n${previousLine}\n`
            }

            isTableProcessed = true
        }

        if (isTableRow && firstLineOfTable === "") {
            firstLineOfTable = line
        }

        if (!isTableRow && isTableProcessed) {
            firstLineOfTable = ""
            isTableProcessed = false
        }

        if (newLine === "") {
            newAnswer += `${emptySymbol}\n`
        } else {
            newAnswer += `${newLine}\n`
        }

        previousLine = line
    })

    newAnswer = newAnswer.replace(/^\n+|\n+$/g, "").trim()
    newAnswer = newAnswer.replace("mermaid ", "mermaid\n")

    //Remove body styles if they are present in format body {... }
    newAnswer = newAnswer.replace(/body\s*{[^}]*}/g, "")

    //Remove empty symbols from the end of the answer
    while (newAnswer.endsWith(`${emptySymbol}\n`)) {
        newAnswer = newAnswer.slice(0, -(`${emptySymbol}\n`.length + 1))
    }

    while (newAnswer.includes("&nbsp;\n```")) {
        newAnswer = newAnswer.replace("&nbsp;\n```", "```")
    }

    let html = ""

    html = String(marked.parse(answer))

    const mermaidBlocks = html.match(/<code class="language-mermaid">([\s\S]*?)<\/code>/g) || []

    mermaidBlocks.forEach(block => {
        const blockContent = block
            .replace(/<code class="language-mermaid">/g, "```mermaid\n")
            .replace(/<\/code>/g, "\n```")
        // .replace(/&quot;/g, '"')
        // .replace(/&ndash;/g, "-")
        // .replace(/&mdash;/g, "--")
        // .replace(/&lt;/g, "<")
        // .replace(/&gt;/g, ">")
        // .replace(/&amp;/g, "&")
        // .replace(/&nbsp;/g, " ")
        // @ts-ignore
        html = html.replace(block, decodeHtmlEntities(blockContent))
    })

    const anyCodeBlocks = html.match(/<code class="language-[^"]*">([\s\S]*?)<\/code>/g) || []
    anyCodeBlocks.forEach(block => {
        // @ts-ignore
        html = html.replace(block, decodeHtmlEntities(block))
    })

    // html = decodeHtmlEntities(html)
    // remove <code> and </code> tags for "<code class="language-html">" blocks
    // html = html.replace(/<code class="language-html">/g, "").replace(/<\/code>/g, "")
    // console.log(html)
    html = html.replace(/<p>/g, "").replace(/<\/p>/g, "").trim()
    html = html.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
    return html
}

const imageCache = new Map()
const pendingRequests = new Map()

async function fetchImageContent(
    fileId: string,
    figureId: string
): Promise<{
    thumbnail: string
    fullImageUrl: string
}> {
    const cacheKey = `${fileId}-fig-${figureId}`

    // Return cached image if available
    if (imageCache.has(cacheKey)) {
        return imageCache.get(cacheKey)
    }

    // Return pending request if one is already in progress for the same file and figure
    if (pendingRequests.has(cacheKey)) {
        return pendingRequests.get(cacheKey)
    }

    const requestPromise = (async () => {
        try {
            const response = await axios.get(
                `/files/get_image/${fileId}-fig-${figureId}.png?file_id=${fileId}&thumbnail=true`,
                {
                    responseType: "arraybuffer",
                }
            )

            const base64 = btoa(
                new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
            )

            // const thumbnail = base64
            const fullImageUrl = `/files/get_image/${fileId}-fig-${figureId}.png?file_id=${fileId}`

            // const imageContent = { thumbnail, fullImageUrl }
            const imageContent = { thumbnail: base64, fullImageUrl: fullImageUrl }

            imageCache.set(cacheKey, imageContent)

            return imageContent
        } catch (error) {
            console.error("Error fetching image:", error)
            return { thumbnail: `[Error loading image: ${fileId}-fig-${figureId}]`, fullImageUrl: "" }
        } finally {
            // Remove the pending request after it's resolved
            pendingRequests.delete(cacheKey)
        }
    })()
    // Store the request in pendingRequests map
    pendingRequests.set(cacheKey, requestPromise)

    return requestPromise
}

export async function parseAnswerToHtml(
    answer: string,
    onCitationClicked?: (citationFilePath: string) => void
): Promise<HtmlParsedAnswer> {
    // console.log("Parsing answer to HTML")
    if (!answer) {
        return {
            fragments: [],
            citations: [],
            followupQuestions: [],
        }
    }
    const followupQuestions: string[] = []

    const answerWithoutFollowUpQuestions = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
        followupQuestions.push(content)

        return ""
    })

    //remove all fonts styles from the answer
    const answerWithoutCustomFonts = answerWithoutFollowUpQuestions
        .replace(/font-family:.*?;/g, "")
        .replace(/@font-face\s*{[^}]*}/g, "")

    const answerWithMarkdown = answer.includes("google.visualization")
        ? answerWithoutCustomFonts.replace("```html", "").replace("```", "")
        : prepareAnswer(answerWithoutCustomFonts)

    const citations: string[] = []

    const citationsRegex = /\[([^:\n]+\.[a-z]{1,6}:[a-f0-9]{24}:\d+:\d\.\d+)]/g
    const imageCitationsRegex = /\[([^:\n]+\.pdf:[a-f0-9]{24}:\d+;\d+)]/g

    const chartRegex = /(```\s*mermaid[\s\S]*?```)/g

    const htmlPageRegex = /<!DOCTYPE html>[\s\S]*<\/html>/g

    const splitRegex = `${citationsRegex.source}|${imageCitationsRegex.source}|${chartRegex.source}|${htmlPageRegex}`
    const parts = answerWithMarkdown.trim().split(new RegExp(splitRegex, "g"))

    const fragments: AnswerFragment[] = []

    const addFragmentToFragments = (text: string | JSX.Element, type: AnswerFragmentType) => {
        const previousFragmentType = fragments.length > 0 ? fragments[fragments.length - 1].type : null
        // const previousFragmentText = fragments.length > 0 ? fragments[fragments.length - 1].text : null
        if (
            previousFragmentType != null &&
            previousFragmentType !== AnswerFragmentType.Chart &&
            type !== AnswerFragmentType.Chart &&
            previousFragmentType !== AnswerFragmentType.Image &&
            type !== AnswerFragmentType.Image
        ) {
            fragments[fragments.length - 1].text += text
        } else if (typeof text == "string" && text.trim() !== emptySymbol) {
            fragments.push({ text, type })
        } else if (typeof text !== "string") {
            fragments.push({ text, type })
        }
    }

    for (const part of parts) {
        if (!part || part.trim() === "") continue

        const is_citation = `[${part}]`.match(citationsRegex) != null
        const is_image_citation = `[${part}]`.match(imageCitationsRegex) != null
        // console.log("Is citation:", is_citation, "Is image citation:", is_image_citation, part)
        const is_chart = part.match(chartRegex) != null || part.includes("google.visualization")

        const is_last = parts.indexOf(part) === parts.length - 1

        let processedPart = part

        if (is_last) {
            processedPart = processedPart.trim()
            while (processedPart.endsWith(emptySymbol)) {
                processedPart = processedPart.trim()
                processedPart = processedPart.slice(0, -(emptySymbol.length + 1))
            }
        }

        if (is_citation && onCitationClicked) {
            let citationIndex: number
            if (citations.indexOf(processedPart) !== -1) {
                citationIndex = citations.indexOf(processedPart) + 1
            } else {
                citations.push(processedPart)
                citationIndex = citations.length
            }

            const citationInfo = processedPart.split(":")

            const citationMarkup = renderToStaticMarkup(
                <a className="supContainer" onClick={() => onCitationClicked(processedPart)}>
                    <sup
                        title={processFileName(citationInfo[0])}
                        style={{ backgroundColor: citationColors[citationIndex - (1 % citationColors.length)] }}
                        data-id={citationInfo[1]}
                        data-page={citationInfo[2]}>
                        {citationIndex}
                    </sup>
                </a>
            )
            addFragmentToFragments(citationMarkup, AnswerFragmentType.Citation)
        } else if (is_image_citation) {
            const [fileName, fileId, pageAndFigure] = processedPart.split(":")
            const [page, figureId] = pageAndFigure.split(";")

            const imageContent = await fetchImageContent(fileId, figureId)
            addFragmentToFragments(JSON.stringify(imageContent), AnswerFragmentType.Image)

            // Add a citation after the image
            const simpleCitation = `${fileName}:${fileId}:${page}`
            citations.push(simpleCitation)
            const citationIndex = citations.length

            const citationMarkup = renderToStaticMarkup(
                <a className="supContainer" onClick={() => onCitationClicked && onCitationClicked(simpleCitation)}>
                    <sup
                        title={processFileName(fileName)}
                        style={{ backgroundColor: citationColors[citationIndex - (1 % citationColors.length)] }}
                        data-id={fileId}
                        data-page={page}>
                        {citationIndex}
                    </sup>
                </a>
            )
            addFragmentToFragments(citationMarkup, AnswerFragmentType.Citation)
        } else if (is_chart) {
            addFragmentToFragments(processedPart, AnswerFragmentType.Chart)
        } else {
            addFragmentToFragments(processedPart, AnswerFragmentType.Text)
        }
    }

    return {
        fragments,
        citations,
        followupQuestions,
    }
}
