import React, { useEffect, useState } from "react"
import { Modal, Box, IconButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import Fade from "@mui/material/Fade"
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch"
import axios from "axios"

// Define the prop types for the ImageModal component
interface ImageModalProps {
    isOpen: boolean
    onClose: () => void
    imageSrc: string
}

const Controls = () => {
    const { zoomIn, zoomOut, resetTransform, centerView } = useControls()

    return (
        <Box
            sx={{
                position: "absolute",
                top: 14,
                left: 16,
                display: "flex",
                flexDirection: "row",
                gap: 1,
                zIndex: 10,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                borderRadius: "4px",
                padding: "2px",
                paddingTop: "0px",
                paddingBottom: "0px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}>
            <IconButton onClick={() => zoomIn()} color="primary">
                <Typography sx={{ fontSize: 14, fontFamily: "Comfortaa", fontWeight: "bold" }}>Zoom In</Typography>
            </IconButton>
            <IconButton onClick={() => zoomOut()} color="primary">
                <Typography sx={{ fontSize: 14, fontFamily: "Comfortaa", fontWeight: "bold" }}>Zoom Out</Typography>
            </IconButton>
            <IconButton onClick={() => resetTransform()} color="primary">
                <Typography sx={{ fontSize: 14, fontFamily: "Comfortaa", fontWeight: "bold" }}>Reset Zoom</Typography>
            </IconButton>
        </Box>
    )
}

const ImageModal: React.FC<ImageModalProps> = ({ isOpen, onClose, imageSrc }) => {
    const [disablePanning, setDisablePanning] = useState(false)
    const [fullImageSrc, setFullImageSrc] = useState("")

    useEffect(() => {
        if (isOpen && imageSrc) {
            const fetchFullImage = async () => {
                try {
                    const response = await axios.get(imageSrc, {
                        responseType: "arraybuffer",
                    })

                    const base64 = btoa(
                        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
                    )

                    setFullImageSrc(base64)
                } catch (error) {
                    console.error("Error fetching full image:", error)
                }
            }

            fetchFullImage()
        }
    }, [isOpen, imageSrc])

    if (imageSrc.toString().length < 50) {
        // console.error("Invalid image source")
        return null
    }

    const handleTransformed = (ref: any, state: { scale: number }) => {
        if (state.scale <= 1) {
            setDisablePanning(true)
        } else {
            setDisablePanning(false)
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="image-modal-title"
            aria-describedby="image-modal-description">
            <Fade in={isOpen} timeout={200}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        borderRadius: "4px",
                        boxShadow: 24,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            zIndex: 10,
                            color: error => error.palette.error.main,
                        }}>
                        <CloseIcon />
                    </IconButton>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            maxWidth: "90vw",
                            maxHeight: "90vh",
                            position: "relative",
                        }}>
                        <TransformWrapper
                            centerOnInit={true}
                            limitToBounds={true}
                            doubleClick={{ mode: "toggle", step: 0.7 }}
                            panning={{ disabled: disablePanning, activationKeys: [], excluded: [] }}
                            pinch={{ excluded: [], step: 5 }}
                            wheel={{ activationKeys: [], excluded: [], smoothStep: 0.001, step: 0.2 }}
                            onTransformed={handleTransformed}>
                            <Controls />
                            <TransformComponent>
                                <img
                                    src={`data:image/png;base64,${fullImageSrc}`}
                                    alt="Zoomable content"
                                    style={{ maxWidth: "90vw", maxHeight: "90vh", paddingTop: "60px" }}
                                />
                            </TransformComponent>
                        </TransformWrapper>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ImageModal
