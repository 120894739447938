// Answer.tsx
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react"
import { Box, Button, Container, Fade, Grid, List, Tooltip, Typography } from "@mui/material"

import { AskResponse, AssistantTypes } from "@/api"
import { ChatAnswerIcon, ChatBot, ReferenceIcon } from "@/assets"

import { styles } from "./styles"
import "./Answer.module.css"
import { AnswerFragmentType, HtmlParsedAnswer, parseAnswerToHtml } from "./AnswerParser"
import { UserChatMessage } from "../UserChatMessage"
import { useReadLocalStorage } from "usehooks-ts"
import { useDialog } from "@/pages/chat/useDialog"
import { citationColors } from "@/theme"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { processFileName } from "@/pages/documents/Documents"
import { tooltipClasses, TooltipProps } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import mermaid from "mermaid"
import { format } from "date-fns"
import { renderChart } from "@/components/Answer/Chart"
import ImageModal from "@/components/ImageModal/ImageModal"

interface IAnswerProps {
    userMessage: string
    answer: AskResponse
    hide_question_on_ui: boolean
    onCitationClicked: (filePath: string) => void
    onThoughtProcessClicked: () => void
    onSupportingContentClicked: () => void
    onFollowupQuestionClicked?: (question: string) => void
    showFollowupQuestions?: boolean
    sx?: CSSProperties
    onRemoveQA: (index: number, questionText: string) => void
    qAndAIndex: number
    onEditConfirm: (message: string, index: number) => void
    onResend: (index: number) => void
    isLastMessage: boolean
    date: string
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 800,
    },
})
export const scoreColor = (score: string) => {
    if (+score >= 0.67) return "success"
    if (+score >= 0.3) return "warning"
    if (!!score && +score < 0.3) return "error"
    return "info"
}

export const Answer: React.FC<IAnswerProps> = ({
    userMessage,
    answer,
    hide_question_on_ui,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    sx,
    onRemoveQA,
    qAndAIndex,
    onEditConfirm,
    onResend,
    isLastMessage,
    date,
}) => {
    if (!answer) return <Typography variant="subtitle1">Error: Answer not available.</Typography>
    const [parsedAnswer, setParsedAnswer] = useState<HtmlParsedAnswer | null>(null)
    const memoizedAnswer = useMemo(() => answer, [answer])

    useEffect(() => {
        if (memoizedAnswer) {
            const parseAnswer = async () => {
                const parsed = await parseAnswerToHtml(memoizedAnswer.answer, onCitationClicked)
                setParsedAnswer(parsed)
            }

            parseAnswer()
        }
    }, [memoizedAnswer, onCitationClicked])

    const { isLoading, sendMessage } = useDialog()
    const answerRef = useRef<HTMLDivElement>(null)
    const showTokens = useReadLocalStorage("showTokens")
    const [isCitationsBoxCollapsed, setCitationsBoxCollapsed] = useState(true)
    const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null)
    const collapseTimeoutRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (!parsedAnswer || !parsedAnswer.fragments) {
            return
        }
        mermaid.initialize({
            startOnLoad: true,
            theme: "neutral",
            securityLevel: "loose",
            fontFamily: "Segoe UI",
            themeVariables: {
                pieTitleTextSize: "20px",
                pieSectionTextSize: "16px",
                pieLegendTextSize: "16px",
            },
        })

        mermaid.contentLoaded()
    }, [parsedAnswer])

    const handleMouseEnter = () => {
        if (collapseTimeoutRef.current) {
            clearTimeout(collapseTimeoutRef.current)
        }
        hoverTimeoutRef.current = setTimeout(() => {
            setCitationsBoxCollapsed(false)
        }, 200)
    }

    const handleMouseLeave = () => {
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current)
        }
        collapseTimeoutRef.current = setTimeout(() => {
            setCitationsBoxCollapsed(true)
        }, 700)
    }

    useEffect(() => {
        return () => {
            if (hoverTimeoutRef.current) {
                clearTimeout(hoverTimeoutRef.current)
            }
            if (collapseTimeoutRef.current) {
                clearTimeout(collapseTimeoutRef.current)
            }
        }
    }, [])

    const scrollToAnswer = (event: React.MouseEvent) => {
        event.preventDefault()
        setTimeout(() => {
            answerRef.current?.scrollIntoView({ block: "nearest" })
        })
    }

    const handleClick = (event: React.MouseEvent) => {
        const target = event.target as HTMLElement
        const href = target.getAttribute("href")

        if (target.tagName === "A" && target.getAttribute("data-id") === null && href) {
            window.open(href, "_blank")
            return
        }

        event.preventDefault()

        if (target.tagName !== "A" && target.tagName !== "BUTTON" && target.tagName !== "SUP") {
            return
        }

        const id = target.getAttribute("data-id")
        const page = target.getAttribute("data-page")

        if (id !== null && page !== null) {
            onCitationClicked(`${id}:${page}`)
            scrollToAnswer(event)
        }
    }

    const formatHighlightText = (texts: string[]): string => {
        return texts
            .map((text, index) => {
                let cleanedText = text.replace(/<[^>]*>/g, "")
                if (cleanedText.length > 700) {
                    cleanedText = cleanedText.slice(0, 700) + "..."
                }
                return `<div>${index + 1}. ${cleanedText}</div>` + (index < texts.length - 1 ? "<br/>" : "")
            })
            .join("")
    }

    const RenderHtmlContent: React.FC<{ htmlContent: string }> = ({ htmlContent }) => (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    )

    const renderCitationItem = (
        fileName: string,
        index: number,
        simpleHighlights: string[] | undefined,
        startIndex = 0
    ) => {
        const adjustedIndex = index + startIndex
        const citationInfo = fileName.split(":")

        const fileNameFixed = processFileName(citationInfo[0])
        const linkText = `${adjustedIndex + 1}. ${fileNameFixed || "Unknown file name"}, page № ${
            Number(citationInfo[2]) || "Unknown page"
        }`
        const highlightsArray = Array.isArray(simpleHighlights) ? simpleHighlights : []

        const formattedHighlight = formatHighlightText(highlightsArray)
        const renderedHighlight = formattedHighlight ? <RenderHtmlContent htmlContent={formattedHighlight} /> : linkText

        return (
            <CustomWidthTooltip title={renderedHighlight} arrow key={adjustedIndex} placement="bottom">
                <div>
                    <Typography
                        key={adjustedIndex}
                        sx={{
                            fontFamily: "Comfortaa",
                            marginLeft: "15px",
                            marginBottom: "12px",
                            fontSize: "12px",
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "start",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                            color: "#062c45",
                        }}
                        component="a"
                        href="#"
                        onClick={e => handleClick(e)}
                        data-id={citationInfo[1]}
                        data-page={citationInfo[2]}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                                marginTop: "-2px",
                            }}>
                            <div
                                style={{
                                    width: "1.1rem",
                                    height: "1.1rem",
                                    backgroundColor: citationColors[index % citationColors.length],
                                    WebkitMaskImage: `url(${ReferenceIcon})`,
                                    maskImage: `url(${ReferenceIcon})`,
                                    WebkitMaskSize: "cover",
                                    maskSize: "cover",
                                }}
                            />
                        </div>
                        {linkText}
                    </Typography>
                </div>
            </CustomWidthTooltip>
        )
    }

    const totalCitationsTextLength = parsedAnswer?.citations?.reduce((acc, item) => acc + item.length, 0) ?? 0
    const averageCitationTextLength = parsedAnswer?.citations?.length
        ? totalCitationsTextLength / parsedAnswer.citations.length
        : 0
    const calculatedCitationsHeight = Math.max(averageCitationTextLength * 11, 100)
    const transitionDuration = isCitationsBoxCollapsed ? "1.1s" : "1s"
    const [isHovering, setIsHovering] = useState(false)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalImageSrc, setModalImageSrc] = useState("")

    const handleImageClick = (src: string) => {
        setModalImageSrc(src)
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
        setModalImageSrc("")
    }

    return (
        <Fade in={true} timeout={200}>
            <Grid
                className="notranslate"
                container
                sx={styles.answerContainer}
                ref={answerRef}
                onClick={scrollToAnswer}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}>
                <Grid item xs={12}>
                    {!hide_question_on_ui && (
                        <UserChatMessage
                            message={userMessage}
                            onSupportingContentClicked={onSupportingContentClicked}
                            onThoughtProcessClicked={onThoughtProcessClicked}
                            answer={answer}
                            onRemoveQA={() => onRemoveQA(qAndAIndex, userMessage)}
                            onEditConfirm={newMessage => onEditConfirm(newMessage, qAndAIndex)}
                            onResend={() => onResend(qAndAIndex)}
                            isLastMessage={isLastMessage}
                            isHovering={isHovering}
                        />
                    )}

                    <Grid container sx={{ padding: "20px", paddingBottom: "5px" }}>
                        <Grid
                            item
                            sx={{
                                width: "36px",
                                paddingBottom: "12px",
                            }}
                            xs="auto">
                            <img
                                style={{ width: "25px" }}
                                src={
                                    answer?.settings?.overrides?.assistant_type === AssistantTypes.BASIC
                                        ? ChatBot
                                        : ChatAnswerIcon
                                }
                                alt="Answer"
                            />
                        </Grid>
                        <Grid item xs={11} sx={{ overflowX: "auto", marginLeft: "20px" }}>
                            {parsedAnswer?.fragments.map((fragment, index) => {
                                if (fragment.type === AnswerFragmentType.Image) {
                                    const imageContent = JSON.parse(fragment.text)
                                    return (
                                        <Box key={index} onClick={() => handleImageClick(imageContent.fullImageUrl)}>
                                            <img
                                                src={`data:image/png;base64,${imageContent.thumbnail}`}
                                                alt={`Image ${index}`}
                                                style={{
                                                    cursor: "pointer",
                                                    width: "auto",
                                                    height: "200px",
                                                }}
                                                // Set the height of the image to the natural height if it's less than 200px
                                                onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
                                                    const target = e.target as HTMLImageElement
                                                    const height = target.naturalHeight
                                                    target.style.height = height < 200 ? `${height}px` : "200px"
                                                }}
                                            />
                                        </Box>
                                    )
                                }
                                if (
                                    fragment.type === AnswerFragmentType.Text ||
                                    fragment.type === AnswerFragmentType.Citation
                                ) {
                                    return (
                                        <Grid
                                            item
                                            key={`${qAndAIndex}-${index}`}
                                            onClick={handleClick}
                                            sx={{ ...styles.answerText, mt: "0px" }}
                                            dangerouslySetInnerHTML={{ __html: fragment.text }}
                                        />
                                    )
                                } else {
                                    return renderChart({
                                        chart: fragment.text,
                                        id: `chart-${qAndAIndex}-${index}`,
                                    })
                                }
                            })}
                            {(answer.was_interrupted || !answer.answer) && (
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: "bold",
                                        marginTop: !answer.answer ? "0px" : "-6px",
                                        paddingBottom: "10px",
                                    }}>
                                    Answer generation stopped
                                </Typography>
                            )}
                        </Grid>

                        <ImageModal isOpen={isModalOpen} onClose={closeModal} imageSrc={modalImageSrc} />
                    </Grid>
                </Grid>

                {!!parsedAnswer?.citations.length && (
                    <Grid
                        item
                        xs={12}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        sx={{
                            maxHeight: isCitationsBoxCollapsed ? "50px" : `${calculatedCitationsHeight}px`,
                            overflow: "hidden",
                            transition: `max-height ${transitionDuration} cubic-bezier(0.25, 1.15, 0.25, 1)`,
                            background: "#f0f4f7",
                        }}>
                        <Container
                            sx={{
                                paddingTop: 1,
                                paddingBottom: 1,
                                marginLeft: "-2px",
                                paddingRight: 2,
                                borderBottomLeftRadius: "4px",
                                borderBottomRightRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                            }}>
                            <Box
                                sx={{
                                    transform: isCitationsBoxCollapsed ? "rotate(0deg)" : "rotate(180deg)",
                                    marginRight: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    transition: `transform ${transitionDuration} cubic-bezier(0.25, 1.15, 0.25, 1)`,
                                }}>
                                <ExpandMoreIcon sx={{ fontSize: "28px", color: "grey" }} />
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    marginLeft: "6px",
                                    display: "flex",
                                    alignItems: "center",
                                }}>
                                Citations
                            </Typography>
                        </Container>

                        <Box
                            className="notranslate"
                            sx={{
                                mt: "0px",
                                ml: "-2px",
                                pb: "10px",
                                marginTop: "10px",
                                paddingLeft: "61px",
                                paddingRight: 2,
                                display: "flex",
                                gap: "10px",
                                flexWrap: "wrap",
                            }}>
                            {parsedAnswer.citations.map((item, index) => {
                                const citations = answer.thoughts.answer_prereq.citations

                                let simpleHighlights = []

                                if (citations.length > index) {
                                    simpleHighlights = citations[index].simple_highlights
                                }

                                return renderCitationItem(item, index, simpleHighlights)
                            })}
                        </Box>
                    </Grid>
                )}

                {!isLoading &&
                    !!parsedAnswer?.followupQuestions.length &&
                    showFollowupQuestions &&
                    onFollowupQuestionClicked && (
                        <Grid item xs={12}>
                            <Container>
                                <Typography variant="subtitle1">Follow-up questions:</Typography>
                                <List>
                                    {parsedAnswer?.followupQuestions.map((question, index) => (
                                        <Button
                                            key={index}
                                            variant="outlined"
                                            onClick={() => sendMessage.mutate({ question, newChatId: undefined })}
                                            sx={{
                                                width: "100%",
                                                mb: "10px",
                                                justifyContent: "flex-start",
                                            }}>
                                            {question}
                                        </Button>
                                    ))}
                                </List>
                            </Container>
                        </Grid>
                    )}
                {!!showTokens && (
                    <Grid item xs={12} sx={{ p: "20px" }}>
                        <Typography variant="subtitle2">
                            {date && new Date(date) instanceof Date && !isNaN(new Date(date).valueOf())
                                ? `${format(new Date(date), "yyyy MMM dd, HH:mm")} | `
                                : ""}
                            Completion tokens: {Math.round((answer?.tokens_used?.completion_tokens ?? 0) * 10) / 10}{" "}
                            Prompt tokens: {Math.round((answer?.tokens_used?.prompt_tokens ?? 0) * 10) / 10} Total
                            tokens: {answer?.tokens_used?.total_tokens ?? ""}
                            {answer?.execution_time !== undefined && answer?.execution_time !== null
                                ? ` | Execution time: ${Math.round(answer?.execution_time * 10) / 10}s | Completion Tokens/sec (rough): ${Math.round((answer?.tokens_used?.completion_tokens ?? 0) / (answer?.execution_time ?? 1))}`
                                : ""}
                            {answer?.settings?.overrides?.model_provider !== undefined &&
                            answer?.settings?.overrides?.model_provider !== null
                                ? ` | Model provider: ${answer?.settings?.overrides?.model_provider}`
                                : ""}
                            {answer?.settings?.overrides?.isChatGPT4 !== undefined &&
                            answer?.settings?.overrides?.isChatGPT4 !== null
                                ? ` | Model: ${answer?.settings?.overrides?.isChatGPT4 ? "GPT4o" : "GPT4o-mini"}`
                                : ""}
                            {answer?.settings?.overrides?.tokens_usage_mode !== undefined &&
                            answer?.settings?.overrides?.tokens_usage_mode !== null
                                ? ` | Tokens usage mode: ${answer?.settings?.overrides?.tokens_usage_mode}`
                                : ""}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Fade>
    )
}
